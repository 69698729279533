import axios from "axios";
import CryptoJS from "crypto-js";
import base64 from "crypto-js/enc-base64";
import store from "@/store";
import _ from "lodash";
let cancelList = {};
const encrypt = (text) => {
	if (typeof text != "string") {
		text = JSON.stringify(text);
	}
	var key = base64.parse(process.env.VUE_APP_KEY); //为了避免补位，直接用16位的秘钥
	var iv = CryptoJS.enc.Latin1.parse(process.env.VUE_APP_IV); //16位初始向量
	var encrypted = CryptoJS.AES.encrypt(text, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.ZeroPadding,
	});
	return btoa(
		JSON.stringify({
			iv: process.env.VUE_APP_IV,
			value: encrypted.toString(),
			mac: CryptoJS.SHA512(iv).toString(),
		})
	);
};
const http = {
	async get(path, data, config = {}, cancelfunc = null) {
		path = (Array.from(path)[0] == "/" ? "" : "/") + path;
		const isloadshow = !config.hide;
		const CancelRandom = (Math.random() * 100000).toString().replace(".", "__");
		const transForm = {
			transformRequest: [
				(data, headers) => {
					if (isloadshow) {
						// store.commit("publicData/startLoading");
					}
					return data;
				},
			],
			transformResponse: [
				(data) => {
					if (isloadshow) {
						// store.commit("publicData/endLoading");
					}
					let redata = data;
					if (typeof data == "string") {
						data = JSON.parse(data);
					}
					return data;
				},
			],
		};

		delete config.hide;
		// config.SameSite = "Strict";
		if (config.headers) {
			config.headers["Authorization"] = localStorage.Authorization ? "Bearer " + localStorage.Authorization : "";
		} else {
			config.headers = {
				Authorization: localStorage.Authorization ? "Bearer " + localStorage.Authorization : "",
			};
		}
		const signalCancel = cancelfunc || path.replace("/", "_");
		if (cancelList[signalCancel]) {
			try {
				_.each(cancelList[signalCancel], (i, k) => {
					// cancelList[cancelfunc][k] ? cancelList[cancelfunc][k].abort() : "";
					i.abort();
				});
				delete cancelList[signalCancel];
			} catch (e) {
				console.log(e);
			}
		}

		cancelList[signalCancel] = cancelList[signalCancel] || {};
		cancelList[signalCancel][CancelRandom] = new AbortController();
		// bodydata.signal = cancelList[signalCancel][CancelRandom].signal;

		
		// if (cancelname && cancelList[cancelname]) {
		// 	cancelList[cancelname]();
		// }

		let tmp = [];
		data = data || {};

		_.forEach(data, (item, keys) => {
			const val = typeof item == "string" || typeof item == "number" ? item : item ? item.join(",") : "";
			tmp.push(keys + "=" + val);
		});
		const CancelToken = axios.CancelToken;
		let cancel;
		//   const bodydata = {
		//     // body: JSON.stringify(body), // must match 'Content-Type' header
		//     cache: 'no-cache',
		//     method: "get",
		//     headers: header,
		// }
		config.headers["sec-fetch-dest"] = "empty";
		config.headers["sec-fetch-mode"] = "cors";
		config.headers["sec-fetch-site"] = "same-site";
		config.headers["company"] = config.headers["company"] || process.env.VUE_APP_COMPANY;
		config.headers["Content-Type"] = "application/json";
		const bodydata = {
			// body: JSON.stringify(data),
			cache: "no-cache",
			method: "get",
			headers: config.headers,
			signal: cancelList[signalCancel][CancelRandom].signal,
		};
		const T = fetch(process.env.VUE_APP_PATH + path.toString() + (tmp.length > 0 ? "?" + tmp.join("&") : ""), bodydata)
			.then(async (response) => {
				if (signalCancel && cancelList[signalCancel] && cancelList[signalCancel][CancelRandom]) {
					delete cancelList[signalCancel][CancelRandom];
				}
				if (response.status !== 200) {
					throw response;
				}

				return { data: await response.json() };
			})
			.catch(async (error) => {
				// if (cancelfunc) {
				//     delete cancelList[cancelfunc];
				// }
				if (signalCancel && cancelList[signalCancel] && cancelList[signalCancel][CancelRandom]) {
					delete cancelList[signalCancel][CancelRandom];
				}
				if (error.status == 404) {
					return {
						static: false,
						message: "404",
					};
				}
				if (typeof error.data == "string") {
					return { static: false, result: { message: error.data } };
				}
				let reError = {};
				try {
					const t = await error.json();

					reError = {
						status: false,
						result: t.result,
					};
				} catch (e) {
					reError = {
						status: false,
						result: {
							message: "No Account",
						},
					};
				}
				return {
					data: reError,
				};
			});
		return T;
		const result = axios({
			method: "get",
			url: path + (tmp.length > 0 ? "?" + tmp.join("&") : ""),
			baseURL: process.env.VUE_APP_PATH + "/",
			data: data,
			responseType: "json",
			cancelToken: new CancelToken((c) => {
				//強行中斷請求要用到的
				cancelname ? (cancelList[cancelname] = c) : "";
			}),
			...transForm,
			...config,
		});
		result.catch((thrown) => {
			// console.log(thrown.response);
		});
		return result;
	},
	post(path, data, config = {}, cancelfunc = null) {
		path = (Array.from(path)[0] == "/" ? "" : "/") + path;
		const isloadshow = !config.hide;
		const CancelRandom = (Math.random() * 100000).toString().replace(".", "__");
		const transForm = {
			transformRequest: [
				(data, headers) => {
					if (isloadshow) {
					}
					if (config.headers && config.headers["Content-Type"] == "multipart/form-data") {
						return data;
					}
					// return data;
					return JSON.stringify(data);
				},
			],
			transformResponse: [
				(data) => {
					if (isloadshow) {
					}
					if (typeof data == "string") {
						data = JSON.parse(data);
					}
					return data;
				},
			],
		};
		delete config.show;
		const signalCancel = cancelfunc || path.replace("/", "_");

		if (cancelList[signalCancel]) {
			try {
				_.each(cancelList[signalCancel], (i, k) => {
					// cancelList[cancelfunc][k] ? cancelList[cancelfunc][k].abort() : "";
					i.abort();
				});
				delete cancelList[signalCancel];
			} catch (e) {
				console.log(e);
			}
		}
		cancelList[signalCancel] = cancelList[signalCancel] || {};
		cancelList[signalCancel][CancelRandom] = new AbortController();
		// bodydata.signal = cancelList[signalCancel][CancelRandom].signal;
		// console.log(signalCancel,cancelList);
		
		// config.SameSite = "Strict";
		if (config.headers) {
			config.headers["Authorization"] = localStorage.Authorization ? "Bearer " + localStorage.Authorization : "";
		} else {
			config.headers = {
				Authorization: localStorage.Authorization ? "Bearer " + localStorage.Authorization : "",
			};
		}
		// if (cancelname && cancelList[cancelname]) {
		// 	cancelList[cancelname]();
		// }
		// const CancelToken = axios.CancelToken;
		config.headers["sec-fetch-dest"] = "empty";
		config.headers["sec-fetch-mode"] = "cors";
		config.headers["sec-fetch-site"] = "same-site";
		config.headers["company"] = config.headers["company"] || process.env.VUE_APP_COMPANY;
		config.headers["Content-Type"] = config.headers["Content-Type"] || "application/json";
		// 'Content-Type': 'application/json',
		// console.log(signalCancel,cancelList);
		const bodydata = {
			body: config.headers["Content-Type"] == "multipart/form-data" ? data : JSON.stringify(data),
			cache: "no-cache",
			method: "post",
			headers: config.headers,
			signal: cancelList[signalCancel][CancelRandom].signal,
		};

		// const result = axios.post(path,data,{
		//   ...config,
		//   baseURL: process.env.VUE_APP_PATH + "/",
		//   cancelToken: new CancelToken(c => { //強行中斷請求要用到的
		//     (cancelname) ? cancelList[cancelname] = c: "";
		//   }),
		//   ...transForm
		// });
		const T = fetch(process.env.VUE_APP_PATH + path, bodydata)
			.then(async (response) => {
				if (signalCancel && cancelList[signalCancel] && cancelList[signalCancel][CancelRandom]) {
					delete cancelList[signalCancel][CancelRandom];
				}
				if (response.status !== 200) {
					throw response;
				}

				return { data: await response.json() };
			})
			.catch(async (error) => {
				// if (cancelfunc) {
				//     delete cancelList[cancelfunc];
				// }
				if (signalCancel && cancelList[signalCancel] && cancelList[signalCancel][CancelRandom]) {
					delete cancelList[signalCancel][CancelRandom];
				}
				if (error.status == 404) {
					return {
						static: false,
						message: "404",
					};
				}
				if (typeof error.data == "string") {
					return { static: false, result: { message: error.data } };
				}
				let reError = {};
				try {
					const t = await error.json();

					reError = {
						status: false,
						result: t.result,
					};
				} catch (e) {
					reError = {
						status: false,
						result: {
							message: "No Account",
						},
					};
				}
				return {
					data: reError,
				};
			});
		return T;
		const result = axios({
			method: "post",
			url: path,
			baseURL: process.env.VUE_APP_PATH + "/",
			data: data,
			responseType: "json",
			cancelToken: new CancelToken((c) => {
				//強行中斷請求要用到的
				cancelname ? (cancelList[cancelname] = c) : "";
			}),
			...transForm,
			...config,
		});

		result.catch((thrown) => {
			if (thrown.response) {
			}
		});
		return result;
	},
};

export default {
	...http,
	encrypt: encrypt,
};
