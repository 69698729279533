// import Vue from "vue";
import { createRouter, createWebHistory, Router } from "vue-router";
// import VueRouter from 'vue-router'
import store from "@/store";
import paths from "./router.path.js";
import WatchJS from "melanke-watchjs";
import _ from "lodash";
import liff from "@line/liff";
import VConsole from "vconsole";
const watch = WatchJS.watch;
// createApp(App).use(VueRouter);

// var paths = require('./router.path.js').default;
// console.log(VueRouter);
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	// mode: process.env.VUE_APP_MODEL,
	base: process.env.BASE_URL,
	routes: paths,
});
const liffinfo = {
	userId: "",
	isLiff: liff.isInClient(),
};

let promiseLiff = null;
let LiffcheckMember = false;
let urlParams = new URLSearchParams(window.location.search);
if (liff.isInClient()) {
	// const vConsole = new VConsole({ theme: "dark" });
	localStorage.Authorization = "";
	localStorage.liffid = urlParams.get("liffid") || localStorage.liffid;
	liff.init({
		liffId: localStorage.liffid, // Use own liffId
		// withLoginOnExternalBrowser: true,
	});
	await liff.ready;
	promiseLiff = true;

	// promiseLiff
	// 	.then(async () => {
	// start to use LIFF's api
	// this.liffInfo.context = liff.getContext();
	// const profile = await liff.getProfile();
	const userId = liff.getContext().userId;
	liffinfo.userId = userId;
	// 	})
	// 	.catch((err) => {
	// 		console.log(err);
	// 	});
} else {
	localStorage.liffid = "";
}

store.commit("member/setLiff", liffinfo);
router.beforeEach(async (to, from, next) => {
	if (to.name == "logout") {
		await store.dispatch("Public/logout");
		// .then(() => {
		// console.log(from, to);
		next({ name: from.name, params: from.params, query: from.query });
		// });
		// return;
	}
	if (!to.name) {
		next({ name: "home" });
	}
	if (to.matched.length === 0) {
		next({ name: "home" });
	}
	if (to.name == "Loginsuccess") {
		store.commit("Public/setpagecache", {
			pagecache: false,
			pagepath: to.name,
		});
		// console.log(to.params.token);
		const token = to.params.token.toString().replace("&2FO", "/").replace("&2Z7", "\\");
		// console.log(token);
		// return ;
		localStorage.Authorization = token;
		const a = await store.dispatch("Public/checkmember");
		next({ name: "success" });
		return;
	}
	// return;
	let checkmember = false;
	let cache = !!to.name;
	window.prerenderReady = false;
	_.each(to.matched, (item, keys) => {
		if (item.meta.checkmember) {
			checkmember = true;
		}
		if (item.meta.noCache) {
			cache = false;
		}
	});

	const path = to.path;
	store.commit("Public/setpagecache", {
		pagecache: cache,
		pagepath: path,
	});
	if (promiseLiff && (!liff.isLoggedIn() || !LiffcheckMember)) {
		LiffcheckMember = true;
		if (!liff.isLoggedIn()) {
			// alert("用戶未登入");
			liff.login();
		} else {
			// alert("用戶已登入");
			const userId = liff.getContext().userId;
			const t = await store.dispatch("Public/Login", {
				method: "line",
				lineId: userId,
			});
		}
	}
	// if (to.name == "ordersearch") {
		// 透過 EMAIL 訂單 進入
		
	// 	if (checkmember) {}
	// 	next();
	// } else {
		// ================其他功能進入============
		// console.log(checkmember);
		if (checkmember) {
			const checkmemberFunc = async () => {
				const t = await store.dispatch("Public/checkmember");
				// console.log(t);
				
				console.log(t.data.status);
				if(t.data.status==false){
					
					const query = { redirect: to.fullPath, nextname: to.name };
					if (to.query && to.liffid) {
						query.liffid = to.liffid;
					}
					router.replace({
						name: "login",
						query: query,
					});
				}else{
					console.log(store.state.Public.Login.isLogin);
						if (store.state.Public.Login.isLogin) {
							// store.dispatch("Public/showToast", {   title: "登入成功",   method: "info",
							// text: "歡迎" + store.state.Public.Login.name });
							next();
						} else {
							
						}
				}
				// t.then((data) => {
				// 	if (store.state.Public.Login.isLogin) {
				// 		// store.dispatch("Public/showToast", {   title: "登入成功",   method: "info",
				// 		// text: "歡迎" + store.state.Public.Login.name });
				// 		next();
				// 	} else {
				// 	}
				// });
				// const query = { redirect: to.fullPath, nextname: to.name };
				// if (to.query && to.liffid) {
				// query.liffid = to.liffid;
				// }
				// t.catch((data) => {
				// 	router.replace({
				// 		name: "login",
				// 		query: query,
				// 	});
				// });
				return t;
			};

			await checkmemberFunc();
		} else {
			next();
		}
	// }
});
router.afterEach((to, from) => {
	window.scrollTo(0, 0);
});
// router.afterEach(route => {
//   // 時間稍微延遲以避免抓取到前個 window.location.href 位置

// })

// const originalPush = Router.push;
// Router.push = function push(location) {
// 	return originalPush.call(this, location).catch((err) => err);
// };

watch(window, ["prerenderReady"], (data) => {});

export default router;
